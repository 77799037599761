import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { videoAssetsPath } from "../../utils/custom/custom.utils";
import Image from "../image/image-component";
import { useDynamicVideoPath } from "../../utils/custom/custom.hooks";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";

import PosterFallback from "../../assets/poster-fallback.png";
import { VideoContainer, ColumnContainer } from "./video-lead.styles";
import { Mobile, TabletAndDesktop } from "../styles/common";
import VideoHiddenData from "../video-hidden-data/video-hidden-data.component";
import LeadHomeTitle from "../leads/lead-home-title/lead-home-title.component";

const VideoLead = ({ _id, displayName, episodePoster, plot, people }) => {
  const path = useDynamicVideoPath(_id, displayName, people);
  const navigate = useNavigate();
  const onClickHandler = (id) => {
    if (!id) {
      console.log("[ERROR: Episode card] no id on click");
      toast("💥 Sorry: this video is not available.");
      return;
    }
    navigate(path);
  };

  // console.log(episodePoster);

  return (
    <ColumnContainer>
      <VideoContainer onClick={() => onClickHandler(_id)}>
        <AspectRatio.Root ratio={16 / 9}>
          <Mobile>
            <Image
              src={videoAssetsPath(episodePoster).poster || PosterFallback}
              alt={`video ${displayName}`}
            />
          </Mobile>
          <TabletAndDesktop>
            <Image
              src={
                videoAssetsPath(episodePoster)?.posterLarge || PosterFallback
              }
              alt={`video ${displayName}`}
            />
          </TabletAndDesktop>
        </AspectRatio.Root>
        <VideoHiddenData path={path} data={{ displayName, plot, people }} />
      </VideoContainer>
      <LeadHomeTitle title={displayName} />
    </ColumnContainer>
  );
};

export default VideoLead;
