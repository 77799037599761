import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

const CategoryHiddenData = ({ path = "/", data = {} }) => {
  const { categoryName } = data;
  return (
    <a
      href={path}
      aria-label={`uTrendTv video${categoryName ? " " + categoryName : ""}`}
    >
      <VisuallyHidden.Root asChild>
        <div>
          <h5>uTrenTv {categoryName ? `${categoryName} video` : "video"}</h5>
        </div>
      </VisuallyHidden.Root>
    </a>
  );
};

export default CategoryHiddenData;
