import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

const PersonHiddenData = ({ path = "/", data = {} }) => {
  const { personName } = data;
  return (
    <a
      href={path}
      aria-label={`uTrendTv video${personName ? " " + personName : ""}`}
    >
      <VisuallyHidden.Root asChild>
        <div>
          <h4>uTrenTv {personName ? `${personName} video` : "video"}</h4>
        </div>
      </VisuallyHidden.Root>
    </a>
  );
};

export default PersonHiddenData;
