import { useWindowWidth } from "@react-hook/window-size";
import Image from "../../image/image-component";
import PosterFallback from "../../../assets/poster-fallback.png";
import { PosterContainer, H4 } from "../../styles/common";
import { videoAssetsPath } from "../../../utils/custom/custom.utils";
import { VideoCardContainer } from "./video-card.styles";
import VideoCardHoverTrigger from "../video-card-hover-trigger/video-card-hover-trigger.component";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERY_LIMITS } from "../../../config";

const VideoCard = ({ width, _id, displayName, episodePoster, ...rest }) => {
  if (typeof _id === "undefined") {
    // Here we let it go... just notify the user on console and remove popup
    // throw new Error("[UtrenderCardLarge]: _id is required");
    console.log("[VideoCard]: _id is required", {
      id: _id,
      diplayName: displayName,
    });
  }
  const viewportWidth = useWindowWidth();

  // distance in pixels from trigger has to be relative to windowView since video-card changes
  const sideOffset = viewportWidth * -0.2;

  //console.log("==> rest", rest) rest contains plot and people from
  //videosCategory

  const props = {
    ...rest,
    _id,
    episodePoster,
    displayName,
    sideOffset,
  };

  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  return (
    <VideoCardContainer
      style={{
        "--videoCardWidth": width,
      }}
    >
      <PosterContainer ratio={16 / 9}>
        <Image
          src={videoAssetsPath(episodePoster).poster || PosterFallback}
          alt={displayName || "Nome episodio"}
        />
      </PosterContainer>
      <VideoCardHoverTrigger {...props} />
      <H4
        style={{
          //width: "80%",
          margin: "0 auto",
          "--line-height": "1",
          ...(isMobile ? { "--font-size": "var(--mini-p-size)" } : {}),
          "--color": "var(--root-color-default)",
          "--text-transform": "uppercase",
          "--font-weight": "var(--root-font-weight-bold)",
          padding: isMobile
            ? "var(--5MPx)"
            : "var(--15MPx) var(--5MPx) calc(var(--1MPx) * 12)",
          textWrap: "balance",
          whiteSpace: "normal",
          wordBreak: "keep-all",
        }}
      >
        {displayName}
      </H4>
    </VideoCardContainer>
  );
};

export default VideoCard;
