import { useNavigate } from "react-router-dom";

import CityHiddenData from "../../city-hidden-data/city-hidden-data.component";

import { cityAssetsPath } from "../../../utils/custom/custom.utils";

import { CityContainer, ButtonContainer } from "./city-card.styles";

const CityCard = ({ item }) => {
  const navigate = useNavigate();
  const path = `/cities/${item.name}`;

  const onClickHandler = () => {
    if (!item.name) {
      console.log("[Error]: no city.name specified");
      return;
    }

    navigate(path);
  };

  return (
    <CityContainer ratio={60 / 29}>
      <img src={cityAssetsPath(item.icon).icon} alt={`logo ${item.name}`} />

      <ButtonContainer onClick={onClickHandler}>
        <CityHiddenData path={path} data={{ cityName: item.name }} />
      </ButtonContainer>
    </CityContainer>
  );
};

export default CityCard;
