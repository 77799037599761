import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useCanonical } from "../../utils/custom/custom.hooks";
import { FB_APP_ID } from "../../config";
import {
  generateTitle,
  generateDescription,
  generateOgImageTags,
} from "../../utils/custom/custom.utils";

const MetaTags = ({
  videoTitle,
  titleParts = [],
  description = "",
  ogMeta = {},
  videoType = false,
}) => {
  const absoluteUrl = useCanonical();

  const seoTitle = useMemo(() => {
    return generateTitle(titleParts);
  }, [titleParts]);

  const actualDescription = useMemo(() => {
    return generateDescription(description);
  }, [description]);

  // We hardcode the domain to point to "utrendtv.com" for canonical even for dev...

  return (
    <Helmet>
      <title>{seoTitle}</title>
      <link rel="canonical" href={absoluteUrl} />
      <meta name="description" content={actualDescription} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta property="og:url" content={absoluteUrl} />
      <meta
        property="og:type"
        content={videoType ? "video.tv_show" : "website"}
      />
      <meta property="og:title" content={videoTitle || seoTitle} />
      <meta property="og:description" content={actualDescription} />
      <meta
        property="og:image"
        content={generateOgImageTags(ogMeta)?.ogImage}
      />
      <meta
        property="og:image:width"
        content={generateOgImageTags(ogMeta).ogImageWidth}
      />
      <meta
        property="og:image:height"
        content={generateOgImageTags(ogMeta).ogImageHeight}
      />
      <meta property="og:locale" content="it_IT" />
      <meta property="og:type" content="website" />
      {FB_APP_ID && <meta property="fb:app_id" content={FB_APP_ID} />}
    </Helmet>
  );
};

export default MetaTags;
