import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

const VideoHiddenData = ({ path = "/", data = {} }) => {
  const { displayName, plot, people } = data;
  return (
    <a href={path} aria-label={`Video${displayName ? " " + displayName : ""}`}>
      <VisuallyHidden.Root asChild>
        <div>
          <h4>{displayName ? displayName : "Video Titolo"}</h4>
          {plot ? <p>{plot}</p> : null}
          {people && people.length > 0 ? (
            <p>{people.reduce((acc, person) => person.name + "," + acc, "")}</p>
          ) : null}
        </div>
      </VisuallyHidden.Root>
    </a>
  );
};

export default VideoHiddenData;
