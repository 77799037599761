import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

const CityHiddenData = ({ path = "/", data = {} }) => {
  const { cityName } = data;
  return (
    <a
      href={path}
      aria-label={`uTrendTv video${cityName ? " " + cityName : ""}`}
    >
      <VisuallyHidden.Root asChild>
        <div>
          <h4>uTrenTv {cityName ? `${cityName} video` : "video"}</h4>
        </div>
      </VisuallyHidden.Root>
    </a>
  );
};

export default CityHiddenData;
