import { forwardRef } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  CATEGORIES_MIN_VIDEOS_NUMBER,
  NUMBER_OF_CITIES,
  //isAtlasDev,
} from "../../../config";

import logoDarkSrc from "../../../assets/logo-dark.svg";
// import { NavigationMenuLink } from "../menu-list-item/menu-list-item.styles";
import MenuContentCategories from "../../menu/menu-content-categories/menu-content-categories.component";
import MenuContentCities from "../../menu/menu-content-cities/menu-content-cities.component";

import {
  BlackLogo,
  DropdownMenuContent,
  NavigationMenuRoot,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
  NavigationMenuContent,
  ViewportPosition,
  NavigationMenuViewport,
  CaretDown,
} from "./menu-content.styles";

export const CATEGORIES_WITH_VIDEOS = gql`
  query CATEGORIES_WITH_VIDEOS($input: Int) {
    topCategories(input: $input) {
      _id
      name
    }
  }
`;

export const CITIES = gql`
  query topCities($input: Int) {
    topCities(input: $input) {
      _id
      name
      icon
      avatar
    }
  }
`;

const MenuContent = forwardRef((props, forwardRef) => {
  const { data: dataCategories } = useQuery(CATEGORIES_WITH_VIDEOS, {
    variables: {
      input: CATEGORIES_MIN_VIDEOS_NUMBER, // 1
    },
  });

  const { data: dataCities } = useQuery(CITIES, {
    variables: {
      input: NUMBER_OF_CITIES,
    },
  });

  return (
    <DropdownMenuContent sideOffset={2}>
      <NavigationMenuRoot defaultValue="menu2">
        <NavigationMenuList>
          <NavigationMenuItem value="menu1">
            <NavigationMenuLink asChild>
              <a aria-label="Home" href="/">
                <BlackLogo src={logoDarkSrc} alt="Logo" />
              </a>
            </NavigationMenuLink>
          </NavigationMenuItem>
          {dataCategories && (
            <NavigationMenuItem value="menu2">
              <NavigationMenuTrigger>
                Categories <CaretDown aria-hidden />
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <MenuContentCategories data={dataCategories?.topCategories} />
              </NavigationMenuContent>
            </NavigationMenuItem>
          )}

          <NavigationMenuItem value="menu3">
            <NavigationMenuTrigger>
              Cities <CaretDown aria-hidden />
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <MenuContentCities data={dataCities?.topCities} />
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuLink href="/utrenders">utrenders</NavigationMenuLink>
          </NavigationMenuItem>

          {/*isAtlasDev && (
            <NavigationMenuItem>
              <NavigationMenuLink href="/agency">Agency</NavigationMenuLink>
            </NavigationMenuItem>
          )*/}
        </NavigationMenuList>

        <ViewportPosition>
          <NavigationMenuViewport />
        </ViewportPosition>
      </NavigationMenuRoot>
    </DropdownMenuContent>
  );
});

export default MenuContent;
