import { ErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from "react-responsive";
import { FreeMode, Lazy, Navigation } from "swiper";
import { SwiperSlide } from "swiper/react";

import UtrenderCard, {
  UTRENDER_CARD_TYPE_CLASSES,
} from "../../../components/cards/utrender-card/utrender-card.component";
import { fallbackRender } from "../../../utils/custom/custom.utils";
import { MEDIA_QUERY_LIMITS, DEFAULT_UTREND_CARD } from "../../../config";

import { SwiperContainer, Arrow } from "./swiper-people-list.styles";

import SVGLeftArrow from "../../icons/arrows/left-arrow.component";
import SVGRightArrow from "../../icons/arrows/right-arrow.component";

const SwiperPeopleList = ({
  data = [],
  cardBorderColor = DEFAULT_UTREND_CARD.borderColor,
  arrowsColor,
  cardType = UTRENDER_CARD_TYPE_CLASSES.slant,
}) => {
  const isDesktop = useMediaQuery({
    minWidth: MEDIA_QUERY_LIMITS.minWidthDesktop,
  });

  return data?.length > 0 ? (
    <SwiperContainer
      modules={[Lazy, FreeMode, Navigation]}
      spaceBetween="1%"
      freeMode={!isDesktop}
      slidesPerView="auto"
      watchSlidesProgress={true}
      preloadImages={false}
      lazy={{
        enabled: true,
        loadPrevNext: true,
        preloaderClass: "image-preloader",
      }}
      navigation={{
        nextEl: ".swiper-people-list-button-next",
        prevEl: ".swiper-people-list-button-prev",
      }}
    >
      {data.map((item) => (
        <SwiperSlide
          key={item._id}
          style={{
            width: "min-content",
            height: "auto",
          }}
        >
          <ErrorBoundary fallbackRender={fallbackRender}>
            <UtrenderCard
              item={item}
              cardType={cardType}
              borderColor={cardBorderColor}
            />
          </ErrorBoundary>
        </SwiperSlide>
      ))}

      <Arrow
        className="swiper-people-list-button-prev"
        style={{
          "--color": arrowsColor,
        }}
      >
        <SVGLeftArrow />
      </Arrow>
      <Arrow
        className="swiper-people-list-button-next"
        style={{
          "--color": arrowsColor,
        }}
      >
        <SVGRightArrow />
      </Arrow>
    </SwiperContainer>
  ) : null;
};

export default SwiperPeopleList;
