import styled from "styled-components/macro";

import { LeadContainer as LeadBase } from "../common/lead.styles";

import { MEDIA_QUERY_LIMITS } from "../../../config";
import { LeadHomeTitleContainerStyle } from "../lead-home-title/lead-home-title.styles";

export const LeadHomeContainer = styled(LeadBase)`
  align-items: start;
  @media (max-width: ${MEDIA_QUERY_LIMITS.maxWidthMobile}px) {
    padding-bottom: var(--root-vertical-space-3);
  }

  ${LeadHomeTitleContainerStyle} {
    display: flex;
  }
`;
