import {
  useSmoothNavigate,
  useDynamicVideoPath,
} from "../../../utils/custom/custom.hooks";

import Image from "../../../components/image/image-component";
import { videoAssetsPath } from "../../../utils/custom/custom.utils";

import PosterFallback from "../../../assets/poster-fallback.png";

import {
  SlantedVideoCardContainer,
  Rank,
  ButtonContainer,
} from "./video-card-slanted.styles";
import { toast } from "react-toastify";
import VideoHiddenData from "../../video-hidden-data/video-hidden-data.component";

const VideoCardSlanted = ({
  _id,
  displayName,
  episodePosterPortrait,
  rank,
  people,
  background,
  plot,
}) => {
  const path = useDynamicVideoPath(_id, displayName, people);
  const navigate = useSmoothNavigate();

  const onClickHandler = (id) => {
    if (!id) {
      console.log("[ERROR: Episode card] no id on click");
      toast("💥 Sorry: this video is not available.");
      return;
    }
    navigate(path);
  };

  return (
    <SlantedVideoCardContainer>
      <Image
        src={
          videoAssetsPath(episodePosterPortrait).posterPortrait ||
          PosterFallback
        }
        alt={displayName || "Nome episodio"}
      />
      <Rank
        style={{
          "--background": background,
        }}
      >
        {rank.toString().padStart(2, 0)}
      </Rank>
      <ButtonContainer onClick={() => onClickHandler(_id)} />
      <VideoHiddenData path={path} data={{ displayName, plot, people }} />
    </SlantedVideoCardContainer>
  );
};

export default VideoCardSlanted;
