import styled from "styled-components/macro";
import { H2 } from "../../styles/common";

export const LeadHomeTitleContainerStyle = styled.div`
  display: none;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
`;

export const LeadHomeTitleStyle = styled(H2)`
  font-size: var(--h1-size);
  text-wrap: balance;
`;
