// cfr: https://beta.reactjs.org/learn/reusing-logic-with-custom-hooks
// If your function doesn’t call any Hooks, avoid the use prefix. Instead, write it as a regular function

import { useNavigate, useLocation } from "react-router-dom";
import { domain } from "../../config";
import { generateUrl, isWebViewAndroid } from "./custom.utils";
import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";

/*
function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return isOnline;
}
*/

export const useMobileBrowsers = () => {
  let hasTouchScreen = false;

  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = matchMedia?.("(pointer:coarse)");
    if (mQ?.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
};

export const useWebViewAndroid = () => {
  return isWebViewAndroid();
};

export const useSmoothNavigate = () => {
  const navigate = useNavigate();

  return (url = "/") => {
    // console.log("url smooth: ", url);
    navigate(url);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
};

// It test if we need to use a relative url
export const useRelativePath = () => {
  const location = useLocation();
  const specialPattern = /^\/orizzonti/;
  return specialPattern.test(location.pathname);
};

// Generate canonical url from path
export const useCanonical = () => {
  const location = useLocation();
  const path = location.pathname;

  if (!path) console.log("[ERROR]: cannot get current path from useLocation");

  return path ? `https://${domain}${path}` : `https://${domain}`;
};

export const useDynamicPersonPath = (_id, peopleName = "", talent = false) => {
  const dynamicPath = useMemo(() => {
    return `/${talent ? "utrenders" : "people"}/${_id}/${generateUrl([peopleName])}`;
  }, [_id, peopleName, talent]);

  return dynamicPath;
};

export const useDynamicVideoPath = (_id, displayName = "", people = []) => {
  const dynamicPath = useMemo(() => {
    const titleParts = [
      ...(people ? people.map((person) => person.name) : []),
      displayName,
    ];
    return `/videos/${_id}/${generateUrl(titleParts)}`;
  }, [_id, displayName, people]);

  return dynamicPath;
};

export const useVideoCardOnClickHandler = (_id, path) => {
  const navigate = useSmoothNavigate();

  const handler = useCallback(() => {
    if (!_id) {
      console.log("[ERROR: Episode card] no id on click");
      toast("💥 Sorry: this video is not available.");
      return;
    }
    //useRelative ? navigate(`videos/${id}`) : navigate(`/videos/${id}`);
    navigate(path);
  }, [_id, path, navigate]);

  return handler;
};
