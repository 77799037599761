import { Routes, Route, Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import { Loading } from "./components/loading/loading.component.jsx";

import { isAtlasDev } from "./config";

import Layout from "./routes/layout/layout.component";
import HomePage from "./routes/home-page/home-page.component";
import { CARD_TYPE } from "./components/grids/videos-grid/videos-grid.component";

import "./App.css";

//const LayoutBlueHorizons = lazy(
//  () => import("./routes/layout-blue-horizons/layout-blue-horizons.component"),
//);
const ProgramPage = lazy(
  () => import("./routes/program-page/program-page.component"),
);
const CategoryPage = lazy(
  () => import("./routes/category-page/category-page.component"),
);
const UtrenderPage = lazy(
  () => import("./routes/utrender-page/utrender-page.component"),
);
const PersonPage = lazy(
  () => import("./routes/person-page/person-page.component"),
);
const CityPage = lazy(() => import("./routes/city-page/city-page.component"));
const UtrendersPage = lazy(
  () => import("./routes/utrenders-page/utrenders-page.component"),
);
const TestVideosPage = lazy(
  () => import("./routes/test-videos-page/test-videos-page.component"),
);
const TestPeoplePage = lazy(
  () => import("./routes/test-people-page/test-people-page.component"),
);
const SearchPage = lazy(
  () => import("./routes/search-page/search-page.component"),
);
/*const AgencyPage = lazy(
  () => import("./routes/agency-page/agency-page.component"),
);
*/
//const MDWPage = lazy(() => import("./routes/mdw-page/mdw-page.component"));
//const BlueHorizonsPage = lazy(
//  () => import("./routes/blue-horizons-page/blue-horizons-page.component"),
//);
//const BlueHorizonsProgramPage = lazy(
//  () =>
//    import(
//      "./routes/blue-horizons-program-page/blue-horizons-program-page.component"
//    ),
//);
const PrivacyPage = lazy(
  () => import("./routes/privacy-page/privacy-page.component"),
);

/*
  const NoAnonWrapper = lazy(() => import("./routes/noAnonWrapper.component"));
  const NoLoggedInWrapper = lazy(() =>
   import("./routes/noLoggedInWrapper.component")
  );

  const SignInForm = lazy(() =>
   import("./components/signin-form/signin-form.component")
  );

  const AuthPage = lazy(() => import("./routes/auth-page/auth-page.component"));

  const AccountPage = lazy(() =>
   import("./routes/account-page/account-page.component")
  );
  const AuthenticationReset = lazy(() =>
   import("./routes/authentication-reset/authentication-reset.component")
  );
  const AuthenticationCallback = lazy(() =>
   import("./routes/authentication-callback/authentication-callback.component")
  );
  const AuthenticationConfirm = lazy(() =>
   import("./routes/authentication-confirm/authentication-confirm.component")
  );
*/

const NoMatch = lazy(() => import("./routes/no-match/no-match.component"));
const DebugPage = lazy(
  () => import("./routes/debug-page/debug-page.component"),
);

function App() {
  const shouldRedirect = !isAtlasDev;

  return (
    <Suspense fallback={<Loading minHeight={"100vh"} />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="videos/:id/*" element={<ProgramPage />} />
          <Route path="utrenders/">
            <Route path=":id/*" element={<UtrenderPage />} />
            <Route index element={<UtrendersPage />} />
          </Route>
          <Route path="categories/:category" element={<CategoryPage />} />
          <Route path="people/:id/*" element={<PersonPage />} />
          <Route path="cities/:city" element={<CityPage />} />
          <Route path="search/">
            <Route path=":searchString" element={<SearchPage />} />
            <Route index element={<Navigate to="/" replace={true} />} />
          </Route>
          {/*
          <Route
            path="agency"
            element={
              shouldRedirect ? <Navigate replace to="/" /> : <AgencyPage />
            }
          />
          <Route
            path="mdw"
            element={shouldRedirect ? <Navigate replace to="/" /> : <MDWPage />}
          />
          */}
          <Route path="privacy" element={<PrivacyPage />} />
          <Route
            path="_i_test/videos"
            element={
              shouldRedirect ? (
                <Navigate replace to="/" />
              ) : (
                <TestVideosPage cardType={CARD_TYPE.test} />
              )
            }
          />
          <Route
            path="_i_test/videos_large"
            element={
              shouldRedirect ? (
                <Navigate replace to="/" />
              ) : (
                <TestVideosPage cardType={CARD_TYPE.testLarge} />
              )
            }
          />
          <Route
            path="_i_test/stories"
            element={
              shouldRedirect ? (
                <Navigate replace to="/" />
              ) : (
                <TestVideosPage cardType={CARD_TYPE.story} />
              )
            }
          />
          <Route
            path="_i_test/people"
            element={
              shouldRedirect ? <Navigate replace to="/" /> : <TestPeoplePage />
            }
          />
          <Route
            path="_i_test/debug"
            element={
              shouldRedirect ? <Navigate replace to="/" /> : <DebugPage />
            }
          />

          {/*
          <Route path="auth" element={<AuthPage />}>
            <Route index element={<SignInForm />} />
          </Route>
          <Route element={<NoAnonWrapper />}>
            <Route
              path="/account"
              element={<PageWrapper page={<AccountPage />} />}
            />
          </Route>
          <Route element={<NoLoggedInWrapper />}>
            <Route path="auth" element={<PageWrapper page={<AuthPage />} />}>
              <Route path="reset" element={<AuthenticationReset />} />
              <Route path="callback" element={<AuthenticationCallback />} />
              <Route path="confirm" element={<AuthenticationConfirm />} />
              <Route index element={<SignInForm />} />
            </Route>
          </Route>
          */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        {/*<Route>
          <Route
            path="/orizzonti"
            element={
              shouldRedirect ? (
                <Navigate replace to="/" />
              ) : (
                <LayoutBlueHorizons />
              )
            }
          >
            <Route index element={<BlueHorizonsPage />} />
            <Route
              path="videos/:id"
              element={<BlueHorizonsProgramPage />}
            />{" "}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Route> */}
      </Routes>
    </Suspense>
  );
}

export default App;
